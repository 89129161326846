.go-to-video {
  display: flex;
  flex-direction: column;
  align-items: center;
}

textarea {
  align-self: stretch;
  min-height: 40vh;
}

a {
  padding: 1rem;
  background-color: rebeccapurple;
  color: white;
  border: none;
  font-size: 1.2em;
  text-decoration: none;
}

a[disabled] {
  cursor: default;
  background-color: #A384C1;
  opacity: .65;
}

button.clear {
  background-color: initial;
  border: none;
  box-shadow: none;
  text-decoration: underline;
  min-height: 40px;
}